import { makeStyles } from "../../../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  const { palette } = theme;
  return {
    checkbox_container: {
      display: "flex",
      flexDirection: "column",
    },
    label: {
      color: palette.grayVariation.grayForCredits + " !important",
    },
  };
});

export { useStyles };
