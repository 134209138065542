import React, { useEffect, useState } from "react";
import Checkbox from "../../../../../common/Checkbox/Checkbox";
import labels from "../../../../../config/labels";
import { useStyles } from "./CheckboxOptions.style";
import useSubmitableContentForm from "../../../useSubmitableContentForm";
import { PHOTO_REQUIRED, SHOW_ENTITY_LOGO } from "../../../diyConstants";
import Typography from "../../../../../common/Typography/Typography";
import Box from "../../../../../common/Box/Box";
import theme from "../../../../../common/Theme/themes/default";

const CheckboxOptions = ({
  show_entity_logo = false,
  photo_required = false,
}) => {
  const [displayLogo, setDisplayLogo] = useState(show_entity_logo);
  const [photoRequired, setPhotoRequired] = useState(photo_required);
  const classes = useStyles();
  const { setProperty } = useSubmitableContentForm();

  useEffect(() => {
    setProperty(SHOW_ENTITY_LOGO, displayLogo);
    setProperty(PHOTO_REQUIRED, photoRequired);
  }, [displayLogo, photoRequired]);

  return (
    <Box className={classes.checkbox_container}>
      <Checkbox
        label={
          <Typography
            level="paragraph_1"
            color="gray"
            bold
            className={classes.label}
          >
            {labels.DISPLAY_ENTITY_LOGO}
          </Typography>
        }
        onChange={() => setDisplayLogo(!displayLogo)}
        checked={displayLogo}
        checkboxColor={theme.palette.themeColor.secondary}
      />

      <Checkbox
        label={
          <Typography
            level="paragraph_1"
            color="gray"
            bold
            className={classes.label}
          >
            {labels.REQUIRE_PHOTO}
          </Typography>
        }
        onChange={() => setPhotoRequired(!photoRequired)}
        checked={photoRequired}
        checkboxColor={theme.palette.themeColor.secondary}
      />
    </Box>
  );
};

export default CheckboxOptions;
